import React from 'react';
import './Header.css';
import { useState, useRef} from 'react';
import { Navbar, Nav, Modal, Button, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import { text } from '@fortawesome/fontawesome-svg-core';

const Header = () => {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("emailjs")
    console.log(form)
    emailjs.sendForm('service_pdgfa8g', 'template_abbkq4n', form.current, 'cEhby2bneSLwToKdo')
      .then((result) => {
          console.log(result);
          setShowModal(false);
          handleShowSuccessModal();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <header className="sticky-header">
      <div className="logo-container">
       <a href='/'><img src="tslogo.png" alt="Logo" className="logo" /></a>
      </div>
      {/* <div className='call-button'>
                <a className='icon' href='tel:9993604332'><i className='fa-solid fa-square-phone'></i></a>
            </div>
            <div className='call-button'>
            <a className='icon' href='https://wa.me/919993604332'><i className='fa-brands fa-square-whatsapp'></i></a>
            </div> */}
      <div className="login-container">
        <button className="login-button" onClick={handleShowModal}>Get In Touch</button>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name="user_name" required="true" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" name="user_email" required/>
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" name="message" required/>
            </Form.Group>
            <button type="submit" className="login-button" style={{marginTop: "10px"}}>
            Send
          </button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>


      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{color:"green"}}>Mail Sent Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color:"green", textAlign:"center", fontSize:"50px"}}>
        <i class="fa-regular fa-circle-check"></i>
        </Modal.Body>
        <Modal.Footer>
          
          <button variant="primary" onClick={handleCloseSuccessModal} className="login-button">
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Header;
